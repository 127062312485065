import React, { lazy } from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';

const SalesSolutionSelector = lazy(() => import('./'));
const SalesSolutionResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/sales-solutions`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(SalesSolutionSelector)}
                />
                <Route
                    exact
                    path={`${url}/:type(insights|marketing-assets|photo-shoots|campaigns|lifestyle|overheads|distributor-ads|bleed-print-ads|digital-assets|print-ads)`}
                    component={WaitingComponent(SalesSolutionSelector)}
                />
                <Route
                    exact
                    path={`${url}/:type(capabilities-presentations|videos|marketing-presentations|new-product-presentations|shared-presentations|other-presentations|training|all)`}
                    component={WaitingComponent(SalesSolutionResults)}
                />
                <Route
                    exact
                    path={`${url}/:type(insights|marketing-assets|photo-shoots|campaigns|lifestyle|overheads|distributor-ads|bleed-print-ads|digital-assets|print-ads)/:subType(menu-pos|menu-powerpoints|technomic-presentations|new-product|logos|contact-sheets|lifestyle-contact-sheets|lifestyle-images|overhead-contact-sheets|overhead-images|other|all|menu-insights-pos-art|distributor-rd-in-design|distributor-and-rd-ads|national-publications|clean-digital-banners|digital-banners-creative|email-blast|email-blast-creative|in-design-print-and-digital|clean-print-ads)`}
                    component={WaitingComponent(SalesSolutionResults)}
                />
            </>
        )}
    />
);

export default Router;
