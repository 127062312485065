import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from './components/header/Header';
import { WaitingComponent } from './helpers/waitingComponent';
import AppsRouter from './containers/apps/AppsRouter';

import './app.scss';

const Login = lazy(() => import('./components/login/Login'));
const RightWrapper = lazy(() => import('./components/right-wrapper'));
const ModalFAQ = lazy(() => import('./components/modals/ModalFAQ'));
const ModalEmailIt = lazy(() => import('./components/modals/ModalEmailIt'));
const ModalRelatedAssets = lazy(() =>
    import('./components/modals/ModalRelatedAssets')
);
const ModalCollection = lazy(() =>
    import('./components/modals/ModalCollection')
);
const ModalCollectionRemove = lazy(() =>
    import('./components/modals/ModalCollectionRemove')
);
const ModalExcelFieldsSelection = lazy(() =>
    import('./components/modals/ModalExcelFieldsSelection')
);
const ModalExcelNameSelection = lazy(() =>
    import('./components/modals/ModalExcelNameSelection')
);
const ModalSampleReqTypeSelection = lazy(() =>
    import('./components/modals/ModalSampleReqTypeSelection')
);
const ModalSampleReqRemoveLead = lazy(() =>
    import('./components/modals/ModalSampleReqRemoveLead')
);
const ModalSampleReqRemoveLeadQuestion = lazy(() =>
    import('./components/modals/ModalSampleReqRemoveLeadQuestion')
);
const ModalSampleReqReassignLead = lazy(() =>
    import('./components/modals/ModalSampleReqReassignLead')
);
const ModalSampleReqShare = lazy(() =>
    import('./components/modals/ModalSampleReqShare')
);

const ModalOrderSupply = lazy(() =>
    import('./components/modals/ModalOrderSupply')
);
const ModalOrderSupplyAramark = lazy(() =>
    import('./components/modals/ModalOrderSupplyAramark')
);
const ModalCollectionShare = lazy(() =>
    import('./components/modals/ModalCollectionShare')
);

const ModalContactRemove = lazy(() =>
    import('./components/modals/ModalContactRemove')
);

const ModalUserRemove = lazy(() =>
    import('./components/modals/ModalUserRemove')
);

const ModalAramarkProfitCenterRemove = lazy(() =>
    import('./components/modals/ModalAramarkProfitCenterRemove')
);

const ModalRecipesBuilderRemove = lazy(() =>
    import('./components/modals/ModalRecipesBuilderRemove')
);

const ModalRecipesBuilderCustomersRemove = lazy(() =>
    import('./components/modals/ModalRecipesBuilderCustomersRemove')
);

const ModalRecipesBuilderEmail = lazy(() =>
    import('./components/modals/ModalRecipesBuilderEmail')
);

const ModalSelectAllOptions = lazy(() =>
    import('./components/modals/ModalSelectAllOptions')
);

const Home = lazy(() => import('./containers/home/Home'));

const PageNotFound = lazy(() =>
    import('./containers/page-not-found/PageNotFound')
);
const SearchResults = lazy(() =>
    import('./containers/search-results/SearchResults')
);

const Reset = lazy(() => import('./containers/reset/Reset'));

const App = ({ location: { pathname } }) => (
    <>
        <div className="page">
            <Helmet>
                <title>Ken's Foodservice | Toolbox</title>
                {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                    <meta name="robots" content="noindex, nofollow" />
                )}
            </Helmet>
            {pathname !== '/reset' ? <Header /> : <></>}
            <div className="main">
                <Switch>
                    <Route exact path="/" component={WaitingComponent(Home)} />
                    <Route
                        path="/search-results"
                        component={WaitingComponent(SearchResults)}
                    />
                    <Route path="/apps" component={AppsRouter} />
                    <Route
                        exact
                        path="/reset/:email/:key"
                        component={WaitingComponent(Reset)}
                    />
                    <Route component={WaitingComponent(PageNotFound)} />
                </Switch>
            </div>
            <Suspense fallback={null}>
                <RightWrapper
                    {...{
                        enableOrderTab: !!pathname.match(/order-supplies/g),
                        enableExcelTab: (() => {
                            if (pathname.match(/excel-builder/g)) {
                                return 'assets';
                            }
                            if (pathname.match(/website-sales-leads/g)) {
                                return 'leads';
                            }
                            return false;
                        })(),
                        enableFilterTab: (() => {
                            if (pathname.match(/website-sales-leads/g)) {
                                return 'lead-filter';
                            }
                            return 'filter';
                        })(),
                    }}
                />
            </Suspense>
        </div>
        {/*
          For preventing Modals duplication on the site,
          kindly place all necessary modals here and toggle them by redux 🙏
      */}
        <Suspense fallback={null}>
            <Login />
            <ModalFAQ />
            <ModalEmailIt />
            <ModalRelatedAssets />
            <ModalCollection />
            <ModalCollectionRemove />
            <ModalCollectionShare />
            <ModalExcelFieldsSelection />
            <ModalExcelNameSelection />
            <ModalSampleReqTypeSelection />
            <ModalSampleReqRemoveLead />
            <ModalSampleReqRemoveLeadQuestion />
            <ModalSampleReqReassignLead />
            <ModalSampleReqShare />
            <ModalOrderSupply />
            <ModalOrderSupplyAramark />
            <ModalContactRemove />
            <ModalUserRemove />
            <ModalAramarkProfitCenterRemove />
            <ModalRecipesBuilderRemove />
            <ModalRecipesBuilderCustomersRemove />
            <ModalRecipesBuilderEmail />
            <ModalSelectAllOptions />
        </Suspense>
    </>
);

export default withRouter(App);
