import React, { lazy } from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';

const ProductImagesSelector = lazy(() => import('./'));
const ProductImagesResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/product-images`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(ProductImagesSelector)}
                />
                <Route
                    exact
                    path={`${url}/:brand(kogi|kens|sbr|salad-bar|all)`}
                    component={WaitingComponent(ProductImagesSelector)}
                />
                <Route
                    exact
                    path={`${url}/:brand(kogi|kens|sbr|salad-bar|all)/:imageType(individual|outer|inner|souffle|all-types|original|styled)`}
                    component={WaitingComponent(ProductImagesResults)}
                />
            </>
        )}
    />
);

export default Router;
