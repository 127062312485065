import { takeLatest, put, call } from 'redux-saga/effects';
import _get from 'lodash/get';

import { actionTypes as types } from './constants';

import ApiClient from '../../helpers/api';

const getPlainDataset = function* (action) {
    const { dataset, successType, errorType } = action;

    try {
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/datasets/plain-dataset',
            { dataset }
        );

        const data = _get(apiData, ['data', 'data'], []);

        yield put({ type: successType, dataset, data });
    } catch (error) {
        console.error(
            `[getPlainDataset for ${dataset}] error:`,
            JSON.stringify(error)
        );

        yield put({ type: errorType, dataset, error });
    }
};

const getDataset = function* (action) {
    const MAP = {
        products: '/api/datasets/products',
        recipes: '/api/datasets/recipes',
        recipesBuilder: '/api/datasets/recipes-builder',
        recipesBuilderCustomers: '/api/datasets/recipes-builder-customers',
        plusones: '/api/datasets/plusones',
        users: '/api/datasets/users',
    };

    const { dataset, payload, successType, errorType } = action;

    const apiUrl = MAP[dataset];

    try {
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            apiUrl,
            payload
        );
        yield put({
            type: successType,
            dataset,
            data: apiData.data,
        });
    } catch (error) {
        console.error(`[getDataset for ${dataset}] error:`, error);

        yield put({ type: errorType, dataset, error });
    }
};

export default [
    takeLatest(types.GET_DATASETS_UOMS_START, getPlainDataset),
    takeLatest(types.GET_DATASETS_PACKAGINGS_START, getPlainDataset),
    takeLatest(types.GET_DATASETS_BRANDNAME_START, getPlainDataset),
    takeLatest(types.GET_DATASETS_COURSES_START, getPlainDataset),

    takeLatest(types.GET_DATASETS_PRODUCTS_START, getDataset),
    takeLatest(types.GET_DATASETS_RECIPES_START, getDataset),
    takeLatest(types.GET_DATASETS_RECIPES_BUILDER_START, getDataset),
    takeLatest(types.GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_START, getDataset),
    takeLatest(types.GET_DATASETS_PLUSONES_START, getDataset),
    takeLatest(types.GET_DATASETS_USERS_START, getDataset),
];
