import React, { lazy } from 'react';
import { Route } from 'react-router';
import get from 'lodash/get';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import AuthHelper from '../../../helpers/auth';

const SalesLeadsResults = lazy(() => import('./Results'));
const SalesLeadDetails = lazy(() => import('./Details'));
const SalesLeadArchive = lazy(() => import('./Archive'));
const SalesLeadReport = lazy(() => import('./Report'));

const Router = ({ path }) => {
    const user = AuthHelper.getUser();

    const groupid = get(user, 'group._id');

    const isAdmin = process.env.REACT_APP_ADMIN_GROUP_ID === groupid;

    return (
        <Route
            path={`${path}/website-sales-leads`}
            render={({ match: { url } }) => (
                <>
                    <Route
                        exact
                        path={`${url}/`}
                        component={WaitingComponent(SalesLeadsResults)}
                    />
                    <Route
                        exact
                        key="salesLeadArchive"
                        path={`${url}/archive`}
                        component={WaitingComponent(SalesLeadArchive)}
                    />
                    <Route
                        exact
                        key="salesLeadArchiveDetails"
                        path={`${url}/archive/:salesLeadId`}
                        component={WaitingComponent(SalesLeadDetails)}
                    />
                    <Route
                        exact
                        key="salesLeadDetails"
                        path={`${url}/:salesLeadId([a-z0-9]{24})`}
                        component={WaitingComponent(SalesLeadDetails)}
                    />
                    {isAdmin ? (
                        <Route
                            exact
                            key="salesLeadReport"
                            path={`${url}/report`}
                            component={WaitingComponent(SalesLeadReport)}
                        />
                    ) : null}
                </>
            )}
        />
    );
};

export default Router;
