import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import homeReducer from './containers/home/reducer';
import cartReducer from './components/cart/reducer';
import loginReducer from './components/login/reducer';
import resetReducer from './containers/reset/reducer';
import filtersReducer from './components/filters/reducer';
import assetTopResultsReducer from './components/top-20/reducer';
import rightWrapperReducer from './components/right-wrapper/reducer';
import assetSearchResultsReducer from './containers/apps/reducer';
import salesLeadsReducer from './containers/apps/sales-leads/reducer';
import collectionsReducer from './components/collections/reducer';
import modalsReducer from './components/modals/reducer';
import builderReducer from './components/excel-builder/reducer';
import orderReducer from './components/order-supply/reducer';
import addressBookReducer from './containers/apps/dashboard/address-book/reducer';
import usersReducer from './containers/apps/dashboard/users/reducer';
import productHistoryReducer from './containers/apps/product-history/reducer';
import dispenserOrdersReducer from './containers/apps/dashboard/track-dispenser-orders/reducer';
import aramarkDispenserOrdersReducer from './containers/apps/aramark/track-dispenser-orders/reducer';
import aramarkProfitCentersReducer from './containers/apps/aramark/profit-centers/reducer';
import squeezeBottleOrdersReducer from './containers/apps/dashboard/track-squeeze-bottle-orders/reducer';
import analyticsReducers from './containers/apps/analytics/store/reducers';
import customerComplaintReducers from './containers/apps/customer-complaint/form/components/store/reducers';
import recipesBuilderReducer from './containers/apps/dashboard/recipes-builder/recipes/store/reducer';
import recipesBuilderCustomersReducer from './containers/apps/dashboard/recipes-builder/customers/store/reducer';
import datasetsReducer from './stores/datasets/reducer';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        home: homeReducer,
        cart: cartReducer,
        login: loginReducer,
        reset: resetReducer,
        filters: filtersReducer,
        rw: rightWrapperReducer,
        top20: assetTopResultsReducer,
        searchResults: assetSearchResultsReducer,
        salesLeads: salesLeadsReducer,
        collections: collectionsReducer,
        modals: modalsReducer,
        builder: builderReducer,
        order: orderReducer,
        addressBook: addressBookReducer,
        users: usersReducer,
        products: productHistoryReducer,
        dispenserOrders: dispenserOrdersReducer,
        aramarkDispenserOrders: aramarkDispenserOrdersReducer,
        aramarkProfitCenters: aramarkProfitCentersReducer,
        squeezeBottleOrders: squeezeBottleOrdersReducer,
        analytics: analyticsReducers,
        customerComplaint: customerComplaintReducers,
        recipesBuilder: recipesBuilderReducer,
        recipesBuilderCustomers: recipesBuilderCustomersReducer,
        datasets: datasetsReducer,
    });
