import { all } from 'redux-saga/effects';

import homeSagas from './containers/home/saga';
import cartSagas from './components/cart/saga';
import loginSagas from './components/login/saga';
import resetSagas from './containers/reset/saga';
import filtersSagas from './components/filters/saga';
import assetsTopResultsSagas from './components/top-20/saga';
import assetsSearchResultsSagas from './containers/apps/saga';
import salesLeadsSagas from './containers/apps/sales-leads/saga';
import collectionsSagas from './components/collections/saga';
import builderSagas from './components/excel-builder/saga';
import orderSagas from './components/order-supply/saga';
import addressBookSagas from './containers/apps/dashboard/address-book/saga';
import usersSagas from './containers/apps/dashboard/users/saga';
import productHistorySagas from './containers/apps/product-history/saga';
import dispenserOrdersSagas from './containers/apps/dashboard/track-dispenser-orders/saga';
import aramarkDispenserOrdersSagas from './containers/apps/aramark/track-dispenser-orders/saga';
import aramarkProfitCentersSagas from './containers/apps/aramark/profit-centers/saga';
import squeezeBottleOrdersSagas from './containers/apps/dashboard/track-squeeze-bottle-orders/saga';
import analyticsSagas from './containers/apps/analytics/store/saga';
import customerComplaintSagas from './containers/apps/customer-complaint/form/components/store/saga';
import recipesBuilderSagas from './containers/apps/dashboard/recipes-builder/recipes/store/saga';
import recipesBuilderCustomersSagas from './containers/apps/dashboard/recipes-builder/customers/store/saga';
import datasetsSagas from './stores/datasets/saga';

export default function* () {
    yield all([
        ...homeSagas,
        ...cartSagas,
        ...loginSagas,
        ...resetSagas,
        ...filtersSagas,
        ...assetsTopResultsSagas,
        ...assetsSearchResultsSagas,
        ...salesLeadsSagas,
        ...collectionsSagas,
        ...builderSagas,
        ...orderSagas,
        ...addressBookSagas,
        ...usersSagas,
        ...productHistorySagas,
        ...dispenserOrdersSagas,
        ...aramarkDispenserOrdersSagas,
        ...aramarkProfitCentersSagas,
        ...squeezeBottleOrdersSagas,
        ...analyticsSagas,
        ...customerComplaintSagas,
        ...recipesBuilderSagas,
        ...recipesBuilderCustomersSagas,
        ...datasetsSagas,
    ]);
}
