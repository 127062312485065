import React, { lazy } from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';

const recipesSelector = lazy(() => import('./'));
const recipesResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/recipes-and-plus-ones`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(recipesSelector)}
                />
                <Route
                    exact
                    path={`${url}/:type(menu-segment|brochures|recipe-pdf|plus-one-pdf|plus-one-pos|recipe-images|original-recipe-images|all|recipe-plus-one-art)`}
                    component={WaitingComponent(recipesResults)}
                />
            </>
        )}
    />
);

export default Router;
