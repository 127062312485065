import React, { lazy } from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';

const ExcelBuilderSelector = lazy(() => import('./'));
const ExcelBuilderResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/excel-builder`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(ExcelBuilderSelector)}
                />
                <Route
                    exact
                    path={`${url}/:type(product|recipe)`}
                    component={WaitingComponent(ExcelBuilderResults)}
                />
            </>
        )}
    />
);

export default Router;
